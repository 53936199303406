import {Spinner, SpinnerSize} from "@fluentui/react";
import React from "react";

interface IProps {
    label?:string
}

const Loading = ({label}:IProps)=>(
    <div style={{display:"flex", justifyContent:'center', alignItems:'center', height:'100vh'}}>
        <Spinner  size={SpinnerSize.large}  label={label ? label:"Loading..."}  />
    </div>
)

export {Loading}