import { IEvent } from "./event";

interface ITimeEntry {
    msdyn_timeentryid?: string;
    msdyn_entrystatus: ETimesheetEntryStatus;
    msdyn_type: ETimesheetEntryType;
    msdyn_start: Date;
    msdyn_end: Date;
    msdyn_description: string;
    msdyn_duration: number;
    illumina_netduration: number;
    "msdyn_bookableresource@odata.bind": string;
    //"msdyn_BookableResourceBooking@odata.bind": string;
    "msdyn_WorkOrder@odata.bind": string;

}

enum ETimesheetEntryStatus {
    Draft = 192350000,
    Returned = 192350001,
    Approved = 192350002,
    Submitted = 192350003,
    Cancelled = 192354320
}

enum ETimesheetEntryType {
    Overtime = 192354320,
    Work = 192350000,
    Absence = 192350001,
    Vacation = 192350002,
    Break = 192355000,
    Travel = 192355001,
    SleepoverPassive = 192355002,
    SleepoverActive = 390950001,
    OnCallActive = 192355003,
    OnCallPassive = 390950002
}
export { ETimesheetEntryStatus, ETimesheetEntryType };
export type { ITimeEntry };