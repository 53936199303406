import { EEventStatus } from "../helpers/calendar";
import { IAvantCareConfigurationModel } from "./avantcareConfig";
import { ChangeStatus } from "./event";

interface ICharacteristic {
   name: string;
   id: string;
   ratingName: string;
}

interface IAvailability {
   resourceId: string,
   availability: EEventStatus
}
interface ITimeOffRequest {
   start: Date;
   end: Date;
   id: string;
}

interface IResource {
   id: string;
   title: string;
   index: number;
   personalNote?: string;
   profileImageUrl?: string;
   minimumWorkHoursPerPayroll?: number;
   maximumWorkHourPerPayroll?: number;
   characteristics: ICharacteristic[];
   calendarId?: string;
   availabilities?: IExpandCalendarAvailabity[];
   siteLocation?: ISiteLocation[];
   maxHoursPerShift?: number;
   categories: IBookableresourcecategory[];
   approvedLeaves: ITimeOffRequest[];
}
interface IBookableresourcecategory {
   id: string;
   name: string;
   description: string;
   displayColour: string;
}
interface IBookableresourcecategoryResponse {
   name: string;
   description: string;
   illumina_displaycolour: string;
   bookableresourcecategoryid: string;
}

interface IBookableresourcecategoryassn {
   _resourcecategory_value: string;
}
interface ITimeOffRequestResponse {
   msdyn_starttime: Date;
   msdyn_endtime: Date;
   msdyn_timeoffrequestid: string;
}

interface IResourceIngress {
   bookableresourceid: string
   name: string;
   minimumWorkHoursPerPayroll: number;
   illumina_maximumworkhourperpayroll?: number;
   illumina_minimumworkhourperpayroll?: number;

   UserId: {
      firstname: string,
      lastname: string
      photoUrl?: string
   }
   illumina_resourcesummary: string
   bookableresource_bookableresourcecharacteristic_Resource: ICharacteristicIngress[]
   calendarid: string
   contactid: string
   _calendarid_value: string;
   illumina_BookableResource_illumina_sitelocation_il: ISiteLocation[];
   illumina_maximumhourspershift: number;
   bookableresource_bookableresourcecategoryassn_Resource: IBookableresourcecategoryassn[];
   msdyn_bookableresource_msdyn_timeoffrequest_Resource: ITimeOffRequestResponse[];
}

interface ICharacteristicIngress {
   bookableresourcecharacteristicid: string
   name: string;
   illumina_expirydate:Date;
   '_ratingvalue_value@OData.Community.Display.V1.FormattedValue': string;
}

interface IBookingStatusResponse {
   bookingstatusid: string;
   name: string;
}

interface IDVBookableResourceBooking { //DV stands for DataVerse
   _bookingstatus_value: string;
   '_bookingstatus_value@OData.Community.Display.V1.FormattedValue': string;
   _resource_value: string;
   bookableresourcebookingid: string;
   illumina_cancellationrequeststatus: ShiftCancellationRequestStatus;
   illumina_cancellationresponsecomments: string;
   illumina_cancellationresponseresolvedon: string | Date;
   illumina_cancellationrequestcomments: string;
}
interface IWorkOrderType {
   illumina_displaycolour: string;
   msdyn_name: string;
   msdyn_workordertypeid: string;
}
interface IWorkOrder {
   msdyn_timewindowstart: string;
   msdyn_timewindowend: string;
   msdyn_workorderid: string;
   msdyn_workordersummary: string;
   msdyn_systemstatus: number;
   msdyn_name: string;
   msdyn_timefrompromised: string;
   msdyn_timetopromised: string;
   _msdyn_preferredresource_value: string;
   '_msdyn_preferredresource_value@OData.Community.Display.V1.FormattedValue': string;
   'illumina_jobtype@OData.Community.Display.V1.FormattedValue': string;
   msdyn_serviceaccount: any; //TODO: Use strongly typed
   illumina_openforshiftjobbidding: boolean;
   msdyn_primaryincidenttype: any;//TODO: Use strongly typed
   msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder: Array<IDVBookableResourceBooking>;
   msdyn_totalestimatedduration: number;
   _msdyn_workordertype_value: string;
   msdyn_workordertype: IWorkOrderType;
}

interface IOtherSiteWorkOrder {
   msdyn_timewindowstart: string;
   msdyn_timewindowend: string;
   msdyn_name: string;
   msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder: Array<IDVBookableResourceBooking>;
   msdyn_totalestimatedduration: number
   msdyn_timefrompromised: string;
   msdyn_timetopromised: string;
   msdyn_workorderid: string;
   msdyn_workordertype: IWorkOrderType;
   _msdyn_workordertype_value: string;
   illumina_jobtype: string;
   illumina_ServiceContact: IContact;
   '_illumina_sitelocationfromid_value@OData.Community.Display.V1.FormattedValue': string;

}

interface IContact {
   fullname?: string;
}


interface IExpandCalendarAvailabity {
   start: Date;
   end: Date;
   timeCode: string;
}
interface IExpandCalendarResponse {
   calendarId: string;
   availabilities: IExpandCalendarAvailabity[];
}

interface IRosteringBookingViewModel {
   id: string;
   jobId: string;
   tempId: string;
   start: Date;
   end: Date;
   bookableResourceId: string | null;
   cost: number;
   status: ChangeStatus;
   bookingId: string;
}

interface IServiceResponse<T> {
   body: T;
   success: boolean;
   errors: string[];
}
interface ILinkedError {
   message: string;
   referenceId: string;
   title: string;
   entityName: string;
   errorType: RosterValidationErrorType;
}
interface IRosterValidationResponse {
   status: string;
   errors: ILinkedError[]
}
interface IBookableResourceRosterValidationResponse {
   bookableResourceId: string;
   id: string;
   errors: ILinkedError[];
}
enum RosterValidationErrorType {
   Unknown = 'Unknown',
   OverTime = 'OverTime',
   MissingSkills = 'MissingSkills',
   ConflictBooking = 'ConflictBooking',
   Unavailable = 'Unavailable',
   ExceededMaxWorkingHours = 'ExceededMaxWorkingHours',
   SaveFailed = 'SaveFailed',
   MinShiftDuration='MinShiftDuration'
}

enum JobType {
   IndividualService = 390950000,
   GroupService = 390950001,
   TransportServices = 390950002,
   ResidentialDisabilityandAgedCareService = 390950003
}





enum ShiftCancellationRequestStatus {
   Pending = 390950000,
   Approved = 390950001,
   Declined = 390950002
}

interface IAvantCareEnvironmentInfo {
   oDataUrl: string;
   resourceUrl: string;
   hardErrorTypes: string[];
   configuration: IAvantCareConfigurationModel;
}

interface ISiteLocation {
   illumina_name: string
   illumina_sitelocationid: string
}

interface IPublishShiftsRequestViewModel {
   siteId: string;
   saveShiftsWithWarning: boolean;
   shifts: IRosteringBookingViewModel[];
}

interface IDraftSavedItem {
   tenantId: string;
   userId: string;
   siteId: string;
   title: string;
   createdOn: string;
   id: string;
   start: string;
   end: string;
}
interface IResourceData {
   maxHoursAllowedPerPayroll: number;
   maxShiftsAllowedPerPayroll: number;
   hoursWorked: number;
   allSitesHoursWorked: number;
   shifts: number,
   allSitesShifts: number,
   requirements: [],
   totalPay: number;
   minimumWorkHoursPerPayroll: number;
   maxHoursPerShift: number;
}
interface IPayrollHourVariationResponse {
   _illumina_payrollcycle_value: string;
   illumina_maximumnoofworkhoursperpayroll: number;
   illumina_minimumnoofworkhoursperpayroll: number;
   _illumina_bookableresource_value: string;
   illumina_payrollcycle: {
      illumina_enddate: string;
      illumina_startdate: string;
   }
}
interface IPayrollHourVariation {
   payrollCycleId: string;
   maximumHoursPerPayroll: number;
   minimumHoursPerPayroll: number;
   bookableResourceId: string;
   payrollStart: Date;
   payrollEnd: Date;
}



export { RosterValidationErrorType, ShiftCancellationRequestStatus, JobType };
export type {
   IResource, ILinkedError, IResourceIngress, ICharacteristic, IAvailability, IDVBookableResourceBooking, IWorkOrder, IOtherSiteWorkOrder, IExpandCalendarResponse, IExpandCalendarAvailabity, IRosteringBookingViewModel, IServiceResponse, IRosterValidationResponse,
   IBookableResourceRosterValidationResponse, IAvantCareEnvironmentInfo, ISiteLocation, IPublishShiftsRequestViewModel, IDraftSavedItem, IResourceData,
   IPayrollHourVariationResponse, IPayrollHourVariation, IWorkOrderType, IBookableresourcecategoryassn, IBookableresourcecategoryResponse, IBookableresourcecategory, ITimeOffRequest,
   IBookingStatusResponse
}