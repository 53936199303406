import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { IProviderProps } from "../interfaces/provider";
import { authContext } from "./auth";

interface IApiContext {
    isLoading: boolean
    get: (uri: string, params?: object) => Promise<any>
    patch: (uri: string, data: object, params?: object) => Promise<any>
    post: (uri: string, data: object, params?: object) => Promise<any>
    remove: (uri: string, params?: object) => Promise<any>
    error?: string
}

const apiContext = createContext<IApiContext>({
    isLoading: false,
    get: async () => ({}),
    patch: async () => ({}),
    post: async () => ({}),
    remove: async () => ({}),
})

const ApiProvider = (props: IProviderProps) => {
    //const params = useParams<TUrlParams>()

    const orgName = localStorage.getItem('orgname')
    const initApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'X-AvantCare-Env': orgName ?? 'org936a573b',
        }
    })

    const api = initApi
    const [error, setError] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { getAccessToken } = useContext(authContext)

    const getConfig = async (params?: object) => {
        const acctoken = await getAccessToken()
        return {
            params,
            headers: {
                'Authorization': `Bearer ${acctoken}`
            }
        }
    }

    const get = async (uri: string, params?: object): Promise<object> => {
        try {
            const res = await api.get(uri, await getConfig(params))
            if (res.status < 400) {
                return res.data
            } else {
                new Error(res.statusText)
            }
        } catch (e: any) {
            setError(e.message)
        }
        return {}
    }

    const patch = async (uri: string, data: object, params?: object): Promise<object> => {
        try {
            const res = await api.patch(uri, data, await getConfig(params))
            //console.log(res)
            if (res.status < 400) {
                return res.data
            } else {
                new Error(res.statusText)
            }
        } catch (e: any) {
            setError(e.message)
        }
        return {}
    }
    const post = async (uri: string, data: object, params?: object): Promise<object> => {
        try {
            const res = await api.post(uri, data, await getConfig(params))
            if (res.status < 400) {
                return res.data
            } else {
                new Error(res.statusText)
            }
        } catch (e: any) {
            setError(e.message)
        }
        return {}
    }
    const remove = async (uri: string, params?: object): Promise<object> => {
        try {
            const res = await api.delete(uri, await getConfig(params))
            if (res.status < 400) {
                return res.data
            } else {
                new Error(res.statusText)
            }
        } catch (e: any) {
            setError(e.message)
        }
        return {}
    }

    return (
        <apiContext.Provider value={{ get, patch, post, remove, isLoading }}>
            {props.children}
        </apiContext.Provider>
    )

}

export { apiContext, ApiProvider }