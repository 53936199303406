import React from 'react'
import {Route, Routes} from "react-router-dom";
import {AuthPage} from "./Auth";
import {Logout} from "./Logout";
import {Providers} from "./Providers";

const AppRoutes = () => (
    <Routes>
        <Route path={'/auth'} element={<AuthPage/>}/>
        <Route path={'/logout'} element={<Logout/>}/>
        <Route path={'/'} element={<Providers/>}/>
    </Routes>
)

export {AppRoutes}