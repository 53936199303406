import { IProviderProps } from "../interfaces/provider";
import { createContext, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { Loading } from "../components/Loading";
import { useNavigate } from "react-router-dom";

interface IAuthContext {
    isAuthed: boolean
    getAccessToken: () => Promise<string | undefined>
    getAccount: () => AccountInfo | undefined
}

const authContext = createContext<IAuthContext>({
    isAuthed: false,
    getAccessToken: async () => (""),
    getAccount: () => undefined
})


const AuthProvider = (props: IProviderProps) => {
    const { instance, accounts, inProgress } = useMsal()
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate()
    const [account, setAccount] = useState<AccountInfo | undefined>(undefined)
    const [apiToken, setApiToken] = useState<string>()

    useEffect(() => {
        console.log(inProgress, "PROGRESS")
    }, [inProgress])

    // useEffect(() => {
    //     if (inProgress === InteractionStatus.None && !isAuthenticated) {
    //         instance.loginRedirect(loginRequest).catch(e => {
    //             console.error("Error: ", e)
    //         }).then((e) => {
    //             console.log("Accounts", accounts)
    //             setAccount(accounts[0])
    //             navigate('/')
    //         })
    //     }
    //
    // }, [])

    useEffect(() => {
        if (inProgress === InteractionStatus.Startup && !isAuthenticated) {
            instance.loginRedirect(loginRequest).catch(e => {
                console.error("Error: ", e)
            }).then((e) => {
                //console.log("Accounts", accounts)
                setAccount(accounts[0])
            })
        } else if (accounts) {
            //console.log("Accounts", accounts)
            setAccount(accounts[0])
        }
        if (isAuthenticated && !apiToken) {
            getAccessToken()
        }

    }, [isAuthenticated])

    useEffect(() => {
        if (!apiToken) {
            getAccessToken()
        }
    }
        , [apiToken])


    const getAccount = () => {
        if (!account && accounts.length > 0) {
            setAccount(accounts[0])
        }
        // console.log(accounts)
        return account ?? accounts[0]
    }


    const getAccessToken = async (): Promise<string | undefined> => {
        const request = {
            ...loginRequest,
            account: account
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        try {
            const auth = await instance.acquireTokenSilent(request)
            if (auth) {
                const token = auth.accessToken
                if (!token) {
                    return
                }

                setApiToken(token);
                return token
            }
        } catch (e) {
            console.error(e)
            await instance.acquireTokenRedirect(request)
        }
    }

    if (inProgress === 'startup') {
        return <Loading />
    }

    if (!isAuthenticated && !apiToken) {
        return <>Please wait</>
    }

    return (
        <authContext.Provider value={{
            isAuthed: isAuthenticated,
            getAccessToken,
            getAccount,
        }}>
            {props.children}
        </authContext.Provider>
    )


}

export {
    AuthProvider, authContext
}