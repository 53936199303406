import React, {createContext, ErrorInfo, useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    IAvailability,
    ICharacteristic,
    IExpandCalendarAvailabity,
    IExpandCalendarResponse,
    IResource,
    IResourceIngress
} from "../interfaces/resource";
import {IProviderProps} from "../interfaces/provider";
import {apiContext} from "./api";
import {authContext} from "./auth";
import {EEventStatus} from "../helpers/calendar";
import {renderGenericFallbackUi} from "../components/error-boundary/generic-fallback-ui";
import {Icon} from "@fluentui/react";

interface IResourceViewContext {
    close: () => void
    open: (event: React.MouseEvent<HTMLDivElement>, resource: IResource) => void
}

const resourcesViewContext = createContext<IResourceViewContext>({
    close: () => {
    },
    open: () => {
    }
})


const ResourceViewProvider = (props: IProviderProps) => {
    const [resource, setResource] = useState<IResource>()
    const [isOpen, setIsOpen] = useState(false)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [yFrom, setYFrom] = useState("top")
    const resourceRef = useRef<IResource>()

    useEffect(() => {
        resourceRef.current = resource
    }, [resource])

    useEffect(() => {
        const closeView = () => {
            close()
        }

        const checkClickOrScroll = (event: Event) => {
            if (resourceRef && resourceRef.current) {
                const target = event.target as HTMLElement
                if (!target.closest(".resourceView")) {
                    close()
                }
            }
        }
        window.addEventListener("click", checkClickOrScroll)
        window.addEventListener("scroll", checkClickOrScroll, true)
        window.addEventListener("resize", closeView)
        return () => {

            window.removeEventListener("scroll", checkClickOrScroll, true)
            window.removeEventListener("resize", closeView)
            window.removeEventListener("click", checkClickOrScroll)
        }
    }, [])


    const setCords = (resourceID: string) => {
        const resColumn = document.getElementsByClassName("fc-scrollgrid-sync-table")[0] as HTMLDivElement
        const resourceCollection = document.getElementsByClassName('fc-resource')
        const resourceList = Array.prototype.slice.call(resourceCollection) as HTMLDivElement[]
        const resLocation = resourceList.find(res => res.dataset.resourceId === resourceID)
        if (!resLocation) {
            return
        }
        const resPosition = resLocation.getBoundingClientRect()
        const xCord = resColumn.offsetWidth
        let yCord = resPosition.y
        if (yCord + 300 >= window.innerHeight) {
            yCord = window.innerHeight - resPosition.y - resLocation.offsetHeight
            setYFrom("bottom")
        } else {
            setYFrom("top")
        }
        setX(xCord)
        setY(yCord)
    }

    const open = (event: React.MouseEvent<HTMLDivElement>, resource: IResource) => {
        setCords(resource.id)
        setResource(resource)
        setIsOpen(true)
    }

    const close = () => {
        setIsOpen(false)
        setResource(undefined)
    }

    return (
        <resourcesViewContext.Provider value={{
            open,
            close
        }}>
            {isOpen && resource ? <div className={"resourceView"} style={{left: x, [yFrom]: y}}>

                    <strong style={{color: '#f37037', fontWeight: 700}}>{resource.title}</strong>

                    <hr className={"divider"}/>
                    {resource.personalNote}
                    <hr/>
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <span style={{fontWeight: '500'}}>Characteristics</span>
                        {/*<div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>*/}
                        {/*    {resource.characteristics.map((rating, index) => <em*/}
                        {/*        style={{marginRight: index + 1 === resource?.characteristics.length ? 0 : '10px'}}>{rating.ratingName}</em>)}*/}
                        {/*</div>*/}
                    </div>

                    <hr className={"divider"}/>

                    <ul className={"charList"}>

                        {resource.characteristics.map(char => {
                            return (
                                <>
                                    <li>{char.name}</li>
                                    <li>{char.ratingName}</li>

                                </>
                            )
                        })}
                    </ul>


                </div> :
                <></>
            }
            {props.children}
        </resourcesViewContext.Provider>
    )
}

export {ResourceViewProvider, resourcesViewContext}