import React, { useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import { Scheduler } from "./components/scheduler/Scheduler";
import { ResourceProvider } from "./providers/resources";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { authContext, AuthProvider } from "./providers/auth";
import { SchedulerPage } from "./pages/Scheduler";
import { ApiProvider } from "./providers/api";
import { PartialTheme, Spinner, SpinnerSize, ThemeProvider } from "@fluentui/react";
import { Loading } from "./components/Loading";
import { ShiftsProvider } from "./providers/shifts";
import { faBell, fas } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import { Logout } from "./pages/Logout";
import { AuthPage } from "./pages/Auth";
import { AppRoutes } from "./pages/Routes";
import { useParams } from 'react-router';
import { TUrlParams } from './interfaces/urlParams';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './applicationInsights';

const theme: PartialTheme = {
    palette: {
        themePrimary: '#f37037',
        themeLighterAlt: '#fef9f7',
        themeLighter: '#fde7de',
        themeLight: '#fbd3c1',
        themeTertiary: '#f7a785',
        themeSecondary: '#f4804f',
        themeDarkAlt: '#da6532',
        themeDark: '#b8552a',
        themeDarker: '#883f1f',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#b4b4b3',
        neutralSecondary: '#9c9b9a',
        neutralPrimaryAlt: '#848382',
        neutralPrimary: '#242323',
        neutralDark: '#545352',
        black: '#3c3b3a',
        white: '#ffffff',
    },

    semanticColors: {
        successIcon: '#6bb700',
        errorIcon: '#c50f1f',
        warningIcon: '#ffaa44',
        infoIcon: '#565656',

        successBackground: '#6bb70030',
        errorBackground: '#c50f1f30',
        warningBackground: '#ffaa4430',
        infoBackground: '#56565630',
    }
}



library.add(fas)


function App() {
    const msalInstance = new PublicClientApplication(msalConfig);

    //Save the orgName before being redirected to login page and the query strings get lost
    //Used raw javascript way as the react one causes the login loopbook.
    const urlParams = new URLSearchParams(window.location.search);
    const orgName = urlParams.get('orgname');
    const siteId = urlParams.get('id');
    if (orgName)
        localStorage.setItem('orgname', orgName);

    if (siteId)
        localStorage.setItem('siteid', siteId);

    return (
        <div id="react-app" className="App">
            <ThemeProvider theme={theme}>
                <style>
                    {`html {
                        --color-successIcon: ${theme.semanticColors?.successIcon};
                        --color-warningIcon: ${theme.semanticColors?.warningIcon};
                        --color-errorIcon: ${theme.semanticColors?.errorIcon};
                        --color-infoIcon: ${theme.semanticColors?.infoIcon};
                        
                        --color-successBackground: ${theme.semanticColors?.successBackground};
                        --color-warningBackground: ${theme.semanticColors?.warningBackground};
                        --color-errorBackground: ${theme.semanticColors?.errorBackground};
                        --color-infoBackground: ${theme.semanticColors?.infoBackground};
                    }`}
                </style>
                <MsalProvider instance={msalInstance}>
                    <AuthProvider>
                        <AppRoutes />
                    </AuthProvider>
                </MsalProvider>
            </ThemeProvider>
        </div>
    );
}

export default withAITracking(reactPlugin, App);
