import { DateRangeType, DefaultButton, PrimaryButton } from "@fluentui/react";
import { add, endOfWeek, format, isEqual, startOfWeek } from "date-fns";
import { ArrowLeft16Regular, ArrowRight16Regular } from "@fluentui/react-icons";
import { Calendar } from "../../calendar/Calendar";
import { DraftControl } from "../../DraftControl/DraftControl";
import React, { useContext, useEffect, useState } from "react";
import { TView } from "../Scheduler";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shiftsContext } from "../../../providers/shifts";
import './assets/header.scss'

interface IProps {
    increaseDate: () => void
    decreaseDate: () => void
    setToCurrentWeek: () => void
    date: Date
    updateDateRange: (date: Date[]) => void
    dateRange: Date[]
    updateView: (view: TView) => void
    view: TView
    setDate: (date: Date) => void
    showOtherSiteShifts: boolean
    toggleShowOtherSitesShifts: () => void
    loadingPayrollData: boolean,
    openNewJobModal: () => void;
}

const Header = (props: IProps) => {

    const { siteLocation } = useContext(shiftsContext);

    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        const fullscreenToggle = () => {
            try {
                setIsFullscreen(window.self === window.top);
            } catch (e) {
                setIsFullscreen(false);
            }
        }
        fullscreenToggle()

        window.addEventListener('fullscreenchange mozfullscreenchange webkitfullscreenchange msfullscreenchange', fullscreenToggle)

        return () => window.removeEventListener('fullscreenchange mozfullscreenchange webkitfullscreenchange msfullscreenchange', fullscreenToggle)
    }, [])

    const renderDate = () => {
        const { date, view } = props
        if (!date) {
            return ('')
        }

        const endDate = new Date();
        endDate.setTime(date.getTime())
        endDate.setDate(endDate.getDate() + (view === "resourceTimelineFortnight" ? 13 : 6))

        return (
            `${format(date, 'do MMMM')} - ${format(endDate, 'do MMMM')}, ${format(date, 'yyyy')}`
        )
    }

    const openFullScreen = () => {
        window.open(window.location.href, '_blank')
    }
    const isInIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    return (
        <div id={"calHeader"} className="rb-header" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px 10px 10px', borderBottom: '1px solid #f2f2f2', boxShadow: '0 0 3px rgba(0,0,0,0.1)' }}>
            <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
                {!isFullscreen
                    ? <PrimaryButton onClick={openFullScreen}> Full Screen </PrimaryButton>
                    : <p style={{ margin: '0 5px 0 0' }}><strong>Site:</strong> {siteLocation}</p>
                }
                <DefaultButton
                    onClick={() => props.updateView(props.view === "resourceTimelineWeek" ? 'resourceTimelineFortnight' : 'resourceTimelineWeek')}
                >
                    {props.view === "resourceTimelineWeek" ? 'Change to Fortnight View' : 'Change to Week View'}
                </DefaultButton>
                <DefaultButton onClick={props.toggleShowOtherSitesShifts}>
                    {props.showOtherSiteShifts ? 'Change to Current Site' : 'Change to All Sites'}
                </DefaultButton>
                {isInIframe() && <DefaultButton onClick={props.openNewJobModal}>
                    New Job
                </DefaultButton>}
            </div>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <DefaultButton disabled={props.loadingPayrollData} onClick={props.decreaseDate} style={{
                    width: "40px",
                    padding: "0",
                    minWidth: 'unset'
                }}><ArrowLeft16Regular /></DefaultButton>

                <Calendar date={props.date} setDateRange={props.updateDateRange}
                    dateRange={props.dateRange}
                    setDate={props.setDate} dateRangeType={DateRangeType.Month}
                    setToCurrentWeek={props.setToCurrentWeek}
                    setToCurrentWeekButtonDisabled={isEqual(props.date, startOfWeek(new Date(), { weekStartsOn: 1 }))}
                    dateRender={renderDate()}
                />

                <DefaultButton disabled={props.loadingPayrollData} onClick={props.increaseDate} style={{
                    width: "40px",
                    padding: "0",
                    minWidth: 'unset'
                }}><ArrowRight16Regular /></DefaultButton>
            </div>
            <DraftControl />

        </div>
    )
}


export { Header }