import {LogLevel} from "@azure/msal-browser";

export function loggerCallback(logLevel: LogLevel, message: string) {
    if (logLevel === LogLevel.Warning || logLevel === LogLevel.Error) {
        console.log(message);
    }
}

export const msalConfig = {
    auth: {
        clientId:  process.env.REACT_APP_MS_CLIENT_ID ??"",
        authority: 'https://login.microsoftonline.com/common', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        // redirectUri: `${window.location.origin}/auth`,
        // postLogoutRedirectUri: `${window.location.origin}/logout`,
        // navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowRedirectInIframe :true,
        loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Warning,
            piiLoggingEnabled: false
        }
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['https://scheduling.avant.care/service_worker']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
};
