import { ErrorInfo } from "react";

export const renderGenericFallbackUi = ({ error, errorInfo, mainHeading, subHeading }: { error?: Error, errorInfo?: ErrorInfo, mainHeading: string, subHeading: string }) => (
    <>
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            background: '#fff'
        }}>
            <h1 style={{ marginBottom: '0', marginTop: '100px' }}>{mainHeading}</h1>
            <h3>{subHeading}</h3>


            {
                error &&
                <details style={{ whiteSpace: 'pre-wrap', height: '450px', textAlign: 'center' }}>
                    <div style={{ maxHeight: '400px', overflow: 'auto', border: '1px solid #808080', background: '#fff', textAlign: 'left' }}>
                        {error?.toString()}
                        <br />
                        {errorInfo?.componentStack}
                    </div>
                </details>
            }
        </div>
    </>
)