import { Scheduler } from "../components/scheduler/Scheduler";
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import { authContext } from "../providers/auth";
import { resourcesContext } from "../providers/resources";
import { Loading } from "../components/Loading";
import EventModal from "../components/scheduler/Events/EventModal";
import { ErrorsModalProvider } from "../components/scheduler/Events/ErrorModal";
import {ResourceViewProvider} from "../providers/resourceView";

const SchedulerPage = () => {
    const { isAuthed, getAccessToken, getAccount } = useContext(authContext)
    const { loading } = useContext(resourcesContext)
    const [selectedShift, setSelectedShift] = useState<string>()

    const account = getAccount()
    if (loading) {
        return <Loading label={`Loading ${loading}`} />
    }

    return (
        <div>
                        <EventModal selectedShift={selectedShift} setSelectedShift={setSelectedShift}/>
                        <Scheduler openModal={setSelectedShift} />
        </div>

    )
}

export { SchedulerPage }