import {
    IconButton,
    Modal,
    Text,
} from "@fluentui/react";
import { Dismiss24Regular, Link24Regular } from "@fluentui/react-icons";
import  { createContext,  useState } from "react";
import { IProviderProps } from "../../../interfaces/provider";
import { ILinkedError, IResource,  } from "../../../interfaces/resource";
import { Table } from "./Table";


const errorModalContext = createContext({
    openErrorModal: (br:IResource, errors: ILinkedError[]) => {
    }
})

const ErrorsModalProvider = (props: IProviderProps) => {
    const [errors, setErrors] = useState<ILinkedError[]>([])
    const [open, setOpen] = useState(false);
    const [bookableResource, setBookableResource] = useState<IResource>();

    const openErrorModal = (br:IResource, errors: ILinkedError[]) => {
        setErrors(errors);
        setBookableResource(br);
        setOpen(true)
    }



    const clearState = () => {
        setOpen(false)
    }

    return (
        <>
            <Modal
                isOpen={open}
                onDismiss={clearState}
                isModeless={false}
                containerClassName={'eventModal bidder-errors'}
            >
                <div className={'modal-header'}>
                    <Text variant={'xxLarge'} block={true}>
                        {bookableResource?.title} -  Errors
                    </Text>
                    <IconButton color={'#000000'} onClick={clearState}><Dismiss24Regular
                        color={"#000000"} /></IconButton>
                </div>
                
                    <div className={'modal-content'}>
                        <div className={"info"}>
                            {errors && errors.length > 0 && <>
                                <Text variant={'xLarge'} block>
                                    Validation Errors
                                </Text>
                                <Table content={errors.map(x=>x.message)} />
                            </>

                            }
                        </div>

                    </div>
                

            </Modal>
            <errorModalContext.Provider
                value={{
                    openErrorModal
                }}>
                {props.children}
            </errorModalContext.Provider>
        </>

    )

}

export { ErrorsModalProvider, errorModalContext }