import { DirectionalHint, FontIcon, IconButton, ITooltipHostStyles, ITooltipProps, TooltipHost, useTheme } from "@fluentui/react"
import { useId, useConst } from '@fluentui/react-hooks';
import { faBell } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from './assets/legend.module.scss'
import { useContext } from "react";
import { shiftsContext } from "../../../providers/shifts";


const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const Legend = () => {

    const { getShiftTypes } = useContext(shiftsContext);
    let shiftTypes = getShiftTypes();

    const tooltipProps: ITooltipProps = {
        onRenderContent: () => {
            return (

                <>
                    <div className={styles.legendContent} key='legends'>
                        <h4 key='lengend_border_styles'>Border Styles</h4>
                        <p key='lengend_draft' className={styles.draft}>Draft</p>
                        <p key='lengend_published' className={styles.published}>Published</p>
                        <p key='lengend_cancellation_request' className={styles.cancelrequestcontainer}>Cancellation Request</p>
                        <br key='lengend_br1' />
                        <h4 key='lengend_border_colours'>Border Colours</h4>
                        <p key='lengend_no_issues' className={styles.noIssues}>
                            No Issues
                        </p>
                        <p key='lengend_soft_alerts' className={styles.softAlert}>Soft Alerts</p>
                        <p key='lengend_hard_alerts' className={styles.hardAlert}>Hard Alerts</p>
                        <p key='lengend_unassigned' className={styles.unassigned}>Unassigned</p>                       
                        <br key='lengend_br2' />
                        <h4 key='lengend_icons'>Icons</h4>
                        <p key='lengend_overtime'><FontIcon iconName="Clock" style={{ color: '--color-warningIcon' }} /> Overtime</p>
                        <p key='lengend_missing_skills'><FontIcon iconName="Library" style={{ color: '--color-warningIcon' }} /> Missing Skills</p>
                        <p key='lengend_not_available'><FontIcon iconName="Warning" style={{ color: '--color-errorIcon' }} /> Resource not available</p>
                        {/* <p><FontAwesomeIcon icon={faBell} color={'#f37037'} /> Flagged for job bidding</p> */}
                        <br />
                        { shiftTypes?.length>0 && <>
                            <h4>Shift Types</h4>
                            {
                                shiftTypes.map(x => <p key={'lengend_shift_type'+x.msdyn_workordertypeid}><span className={styles.eventTypeColor} style={{ backgroundColor: x.illumina_displaycolour, display: 'inline-block' }}></span> {x.msdyn_name}</p>)
                            }
                        </>
                        }
                    </div>
                </>
            )
        }
    };

    const theme = useTheme();
    const tooltipId = useId('legend');
    const buttonId = useId('targetButton');

    const calloutProps = useConst({
        // If the tooltip should point to an absolutely-positioned element,
        // you must manually specify the callout target.
        target: `#${buttonId}`,
        gapSpace: 5,
    });

    return (
        <>
            <TooltipHost
                id={tooltipId}
                key={tooltipId}
                tooltipProps={tooltipProps}
                calloutProps={calloutProps}
                directionalHint={DirectionalHint.topRightEdge}
            >
                <IconButton
                    aria-describedby={tooltipId}
                    iconProps={{ iconName: 'Help', className: styles.legendIcon }}
                    disabled={false}
                    className={styles.legend}
                    key={buttonId}
                    id={buttonId}
                    style={{ backgroundColor: theme.palette.themePrimary, color: theme.palette.white }}
                />
            </TooltipHost>
        </>
    )
}

export default Legend
