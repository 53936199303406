import React, {useContext} from "react";
import {authContext} from "../providers/auth";
import {Loading} from "../components/Loading";
import {ApiProvider} from "../providers/api";
import {ResourceProvider} from "../providers/resources";
import {ShiftsProvider} from "../providers/shifts";
import {SchedulerPage} from "./Scheduler";
import { ResourceViewProvider } from "../providers/resourceView";
import { ErrorsModalProvider } from "../components/scheduler/Events/ErrorModal";

const Providers = ()=> {
    const {isAuthed} = useContext(authContext)
    // if(!isAuthed){
    //     return (<Loading label={'Logging in...'}/>)
    // }
    return (
        <>
            <ApiProvider>
                <ResourceProvider>
                    <ShiftsProvider>
                        <ErrorsModalProvider>
                            <ResourceViewProvider>
                                <SchedulerPage/>
                            </ResourceViewProvider>
                        </ErrorsModalProvider>
                    </ShiftsProvider>
                </ResourceProvider>
            </ApiProvider>
        </>


    )
}

export {Providers}