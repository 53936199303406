import React from "react";

interface IContent {
    name: string,
    content: string
}

interface IProps {
    content: IContent[] | string[]
}

const Table = (props: IProps) => {
    const tableRender = props.content.map(cont => {
        let tableInner = <></>
        // @ts-ignore
        if (typeof cont === 'object') {
            tableInner = (<>
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                    <div className="LayoutPage-demoBlock">{cont.name}</div>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                    <div className="LayoutPage-demoBlock">{cont.content}</div>
                </div>
            </>)
        } else {
            tableInner = (
                <div className="ms-Grid-col ms-sm12">
                    <div className="LayoutPage-demoBlock">{cont}</div>
                </div>
            )
        }
        return (

            <div className="ms-Grid-row">
                {tableInner}
            </div>

        )
    })
    return (
        <>
            <div className="ms-Grid">
                {tableRender}
            </div>
        </>
    )
}


export {Table}