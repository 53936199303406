import { ResourceApi, ResourceLabelContentArg } from "@fullcalendar/resource";
import React, { useContext, useState } from "react";
import './assests/Resource.scss'
import { resourcesContext } from "../../../providers/resources";
import { ICharacteristic, IResource } from "../../../interfaces/resource";
import { IconButton, Callout, DefaultButton, Text, DelayedRender } from "@fluentui/react";
import { EyeHideRegular } from "@fluentui/react-icons";
import { useBoolean } from "@fluentui/react-hooks";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoHover from "./InfoHover";


const ResourceRender = (resource: ResourceLabelContentArg) => {
    const res = resource.resource
    const bookableResource = res.extendedProps as IResource;

    const getInitials = () => {
        if (res.title) {
            const nameArray = res.title.split(" ")
            if (nameArray.length === 2) {
                return `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`
            }
            return `${nameArray[0].charAt(0)}`
        }
        return ""

    }
    const formatName = () => {
        if (res.title) {
            const nameArray = res.title.split(" ")
            if (nameArray.length === 2) {
                return <span>{nameArray[0]} <br /> {nameArray[1]} </span>
            }
            return <span>{nameArray[0]}</span>
        }
        return ""

    }

    const renderIcon = () => {
        if (res.id === '0') {
            return
        }
        if (bookableResource?.profileImageUrl) {
            return (<img src={bookableResource.profileImageUrl} />)
        }
        return (<div className={"initials"}>
            {getInitials()}
        </div>)
    }
    const getCategoryColours = () => {
        if (res.id === '0' || !bookableResource.categories?.length) {
            return
        }
        const listItems = bookableResource.categories.map(x =>
            <div key={x.id}>
                <span className="resource-colour-box" style={{ backgroundColor: x.displayColour }} title={x.name}></span>
            </div>
        );
        return (<div className="resource-colour-box-container">{listItems}</div>);
    }


    const isUnassigned = res.id === '0'


    // @ts-ignore
    return (
        <div>
            <div style={{ display: 'relative' }} className={'resourceItem'} data-resource={res.id}>
                <div className={"iconContainer"}>
                    {!isUnassigned &&
                        <div className={"imageBox"}>
                            {renderIcon()}
                        </div>
                    }
                </div>

                <div>
                    <p style={{ margin: 0, fontSize: isUnassigned ? '20px' : '14px' }}><strong>{formatName()}</strong></p>
                </div>

                {!isUnassigned && <InfoHover resource={resource.resource} view={resource.view} />}

            </div>
            {getCategoryColours()}
        </div>
    )
}

export { ResourceRender }