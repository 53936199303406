import {useCallback, useRef} from "react";
import {useBoolean} from '@fluentui/react-hooks';
import {
    Calendar as FluentCalendar,
    Callout,
    DateRangeType,
    DefaultButton,
    defaultCalendarStrings,
    DirectionalHint,
    Text,
    FocusTrapZone,
    PrimaryButton,
} from '@fluentui/react';
import {format, startOfWeek} from "date-fns";

interface IProps {
    date: Date
    setDate: (date:Date)=>void
    dateRange: Date[]
    setDateRange: (date:Date[])=>void
    dateRangeType: DateRangeType
    setToCurrentWeek: () => void
    setToCurrentWeekButtonDisabled: boolean
    dateRender: string
}

const Calendar = ({date, setDate,dateRange,setDateRange, dateRangeType, setToCurrentWeek, setToCurrentWeekButtonDisabled, dateRender}: IProps)=> {
    const [showCalendar, {toggle: toggleCalendar, setFalse: hideCalendar}] = useBoolean(false)
    const buttonContainerRef = useRef<HTMLDivElement>(null);

    const onSelectDate = (date: Date, dateRangeArray?: Date[]) => {
        setDate(date);
        if(dateRangeArray){
            setDateRange(dateRangeArray);
        }
    }

    return (
        <div>
            <div onClick={toggleCalendar} ref={buttonContainerRef} >
                <Text style={{padding:'1rem 1.5rem'}} className={'calendar-text'} variant={'xxLarge'}>{dateRender}</Text>
            </div>
            {showCalendar && (
                <Callout
                    isBeakVisible={false}
                    gapSpace={0}
                    doNotLayer={false}
                    target={buttonContainerRef}
                    directionalHint={DirectionalHint.bottomCenter}
                    onDismiss={hideCalendar}
                    setInitialFocus
                >
                    <FocusTrapZone isClickableOutsideFocusTrap>
                        <FluentCalendar
                            dateRangeType={dateRangeType}
                            onSelectDate={onSelectDate}
                            onDismiss={hideCalendar}
                            isMonthPickerVisible
                            value={date}
                            highlightCurrentMonth
                            isDayPickerVisible
                            showGoToToday={false}
                        />
                        
                        <div style={{display: 'flex', justifyContent: 'flex-end', padding: '0 5px 5px 0'}}>
                            <PrimaryButton onClick={setToCurrentWeek}
                                        disabled={setToCurrentWeekButtonDisabled}>
                                            This Week
                                </PrimaryButton>
                        </div>
                    </FocusTrapZone>
                </Callout>
            )}
        </div>
    )
}

export {Calendar}