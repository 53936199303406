import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

export const browserHistory = createBrowserHistory();
export var reactPlugin = new ReactPlugin();
export var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();